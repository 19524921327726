
import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";
import Search from "@/components/reusable/Search.vue";
import {
  AssetModel,
  AssetModelRequest,
  AssetRequestOptions
} from "@/models/asset";
import Pagination from "@/components/reusable/table/Pagination.vue";
import UIkit from "uikit";
import AssetService from "@/services/asset_service";
import { EventBus } from "@/events/index";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { namespace } from "vuex-class";
import { AuthError } from "@/services/error_service";
import { APIResponse } from "@/models/api_res";
import moment from "moment";
import { getImage, formatAssetCrumb, fileTypeDisplay } from "@/utility/helpers";

const sortValueOptions = [
  "file_type",
  "size_h",
  "last_modified",
  "filename"
] as const;

@Component({
  components: {
    Search,
    Pagination,
    Icon
  }
})
export default class AssetFileSelector extends Vue {
  protected assetService = new AssetService();
  @Prop() header!: string;
  @Prop({ default: false }) image!: boolean;
  @Prop({ default: "sendImage" }) callback!: string;
  protected loading = false; // we don't use the global variable since this is a modal and global isLoading forces everything to rerender
  protected moment = moment;
  protected fileTypeDisplay = fileTypeDisplay;
  protected getImage = getImage;
  protected assets: any[] = [{}];
  protected pages = 1;
  protected formatAssetCrumb = formatAssetCrumb;
  protected currentPage = 1;
  protected query = "";
  protected breadcrumbs: string[] = [];
  protected sortFields = ["file_type", "size_h", "last_modified", "filename"];
  protected sort: {
    value: typeof sortValueOptions[number];
    order: "asc" | "desc";
  } = { value: "filename", order: "asc" };
  protected selected: number[] | number = [];
  protected selectedItems: AssetModel[] = [];
  protected optionsObject = {} as AssetRequestOptions; // in other components, this info is pulled from query params, but the file selector is a modal so we need to save the info to an instance variable
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;
  created() {
    this.loading = true;
    if (this.image) {
      this.optionsObject.hide = ["doc"];
    }
    this.optionsObject.path = "/assets";
    const options: AssetRequestOptions = this.getRequestOptions();
    this.getAssets(options);
  }

  protected submitSelected(): void {
    this.$emit("close");
    if (typeof this.selected === "number") {
      this.selectedItems = this.assets.filter(
        asset => asset.id === this.selected
      );
    } else {
      this.selectedItems = [];
      (this.selected as number[]).forEach(id => {
        this.assets.forEach(asset => {
          if (id === asset.id) {
            this.selectedItems.push(asset);
          }
        });
      });
    }
    EventBus.$emit(this.callback, this.selectedItems);
  }

  /**
   * Setting sort properties on user selection, and toggling column arrows based on asc/desc
   */
  public selectSort(sortByValue: typeof sortValueOptions[number]): void {
    if (this.sort.value !== sortByValue) {
      this.sort.value = sortByValue;
      this.sort.order = "asc";
      (this.$refs[sortByValue] as HTMLSpanElement).innerHTML =
        '<span class="sort sort-up"></span>';
      this.sortFields.forEach(field => {
        if (this.$refs[field]) {
          (this.$refs[field] as HTMLSpanElement).innerHTML =
            '<span class="sort sort-up"></span>';
        }
      });
    } else {
      if (this.sort.order === "desc") {
        this.sort.order = "asc";
        (this.$refs[sortByValue] as HTMLSpanElement).innerHTML =
          '<span class="sort sort-up"></span>';
      } else {
        this.sort.order = "desc";
        (this.$refs[sortByValue] as HTMLSpanElement).innerHTML =
          '<span class="sort sort-down"></span>';
      }
    }
  }

  protected goBack(destination?: number) {
    const len = this.breadcrumbs.length;
    if (!destination) {
      destination = len - 2;
    }
    this.optionsObject = {} as AssetModelRequest;
    if (this.image) {
      this.optionsObject.hide = ["doc"];
    }
    if (destination) {
      this.breadcrumbs.splice((destination as number) + 1);
      this.optionsObject.path = "/assets/" + this.breadcrumbs.join("/");
    } else {
      this.breadcrumbs = [];
      this.optionsObject.path = "/assets";
    }
    this.getAssets(this.optionsObject);
  }

  get filteredData(): any[] {
    const filtered = this.assets;

    if (this.sort.order === "asc") {
      //@ts-ignore
      filtered.sort((first, second) => {
        if (
          first[this.sort.value].toLowerCase() <
          second[this.sort.value].toLowerCase()
        )
          return -1;
        if (
          first[this.sort.value].toLowerCase() >
          second[this.sort.value].toLowerCase()
        )
          return 1;
      });
    }
    if (this.sort.order === "desc") {
      //@ts-ignore
      filtered.sort((first, second) => {
        if (
          first[this.sort.value].toLowerCase() <
          second[this.sort.value].toLowerCase()
        )
          return 1;
        if (
          first[this.sort.value].toLowerCase() >
          second[this.sort.value].toLowerCase()
        )
          return -1;
      });
    }

    return filtered;
  }

  protected getRequestOptions(): AssetRequestOptions {
    return this.optionsObject;
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal"
    ];
    modals.forEach(selector => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }
  /**
   * Method that checks/unchecks all checkboxes if master checkbox $ref.inputAll is changed
   * All inputs are assigned same ref so $ref.inputRow is an array
   * Iterate over array to check boxes on/off
   */
  public checkAllBoxesRows() {
    if ((this.$refs.inputAll as HTMLInputElement).checked) {
      (this.$refs.inputRow as HTMLInputElement[]).forEach(input => {
        if (typeof this.selected !== "number" && !input.checked) {
          input.checked = true;
          this.selected.push(parseInt(input.value, 10));
        }
      });
    } else {
      this.resetAllCheckboxRowData();
    }
  }

  protected selectRow(rowId: number, index: number, file: AssetModel): void {
    if (file.file_type !== "dir") {
      if (this.image && this.selected === rowId) {
        this.selected = 0;
      } else if (this.image) {
        this.selected = rowId;
      } else if ((this.selected as number[]).includes(rowId)) {
        (this.selected as number[]).splice(index, 1);
      } else {
        (this.selected as number[]).push(rowId);
      }
    } else {
      const options: AssetRequestOptions = { path: file.path + file.filename };
      if (this.$route.query.hide) {
        options.hide = [this.$route.query.hide] as string[];
      }
      this.optionsObject = options;
      this.getAssets(options);
      this.getBreadcrumbs(this.optionsObject.path as string);
    }
  }

  protected getBreadcrumbs(path: string) {
    const crumbs = path.split("/");
    if (crumbs.length > 2) {
      crumbs.splice(0, 2);
      this.breadcrumbs = crumbs;
    }
  }
  /**
   * Clears all ids from this.selected
   */
  public resetAllCheckboxRowData(): void {
    if (typeof this.selected !== "number" && this.selected.length > 0) {
      // (this.$refs.inputRow as HTMLInputElement[]).forEach((input) => {
      //   input.checked = false;
      // });
      this.selected = 0;
    } else {
      (this.$refs.inputAll as HTMLInputElement).checked = false;
      this.selected = [];
    }
  }

  protected isSelected(rowId: number): boolean {
    let checked = false;
    if (this.image) {
      if (this.selected === rowId) {
        checked = true;
      }
    } else {
      if ((this.selected as number[]).includes(rowId)) {
        checked = true;
      }
    }
    return checked;
  }

  protected async getAssets(
    optionsObject?: AssetRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.assetService.getAssets(optionsObject);
      this.assets = res.results;
      this.pages = res.meta.total_pages;
      this.currentPage = res.meta.page;
      this.loading = false;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * Paginate method triggered by child <Pagination> component.
   * First, calls this.getRequestOptions() to retain any other options (like query term)
   * NOTE: page property in returned options object must be overwritten -- this.getRequestOptions will return the current page.
   *
   * Second, send options object into API get request to get appropriate page of data.
   *
   * Finally, add page query to url if it is not already there. This ensure the correct page query is retained and will fetch correct data if user refreshes.
   */
  protected paginate(page: number) {
    this.optionsObject.page = page;
    this.getAssets(this.optionsObject);
  }

  public receiveSearchTerm(query: string): void {
    this.query = query;
    this.optionsObject.q = query;
    this.optionsObject.page = 0;
    this.optionsObject.path = "";
    this.getAssets(this.optionsObject);
  }

  /** Reset search by re-requesting unfiltered data */
  public reset(): void {
    this.query = "";
    this.optionsObject.q = "";
    this.optionsObject.path = "/assets";
    this.getAssets(this.optionsObject);
  }
}
